* {
  font-family: 'Inter', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

body {
  background-color: var(--color-black);
}
.footer-content,
.footer-content-wrapper,
.landing {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.footer-content-wrapper,
.landing {
  box-sizing: border-box;
}
.footer-content-wrapper {
  justify-content: flex-start;
  gap: var(--gap-base);
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--black-60);
  font-family: var(--font-inter);
  margin: 0 auto;
}
.footer-content {
  box-sizing: border-box;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: var(--gap-xs);
  flex-flow: wrap;
  display: flex;
  flex-direction: column;
  align-content: center;
}
.landing {
  width: 100%;
  position: relative;
  background-color: var(--color-black);
  overflow: hidden;
  flex-direction: column;
  padding: 0 0 var(--padding-13xl);
  line-height: normal;
  letter-spacing: normal;
}
.frame-parent,
.homepage {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.homepage {
  position: sticky;
  top: 0;
  z-index: 10;
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
}
.homepage-wrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  max-width: 1440px;
  margin: 0 auto;
  align-self: stretch;
  justify-content: space-between;
  padding: 12px var(--padding-base);
  gap: var(--gap-xl);
}
.homepage-child {
  align-self: stretch;
  position: relative;
  max-height: 100%;
  min-height: 36px;
}
.frame-wrapper {
  cursor: pointer;
  height: 52px;
  width: 52px;
  box-shadow: 0-1px 0 rgba(0, 0, 0, 0.25) inset;
  border-radius: var(--br-13xl);
  background-color: var(--black-85);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs);
  box-sizing: border-box;
}
.frame-icon {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.st {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 80px var(--padding-base) 452px;
  position: relative;
  gap: var(--gap-21xl);
  text-align: center;
  font-size: 48px;
  color: var(--black-0);
  font-family: var(--font-inter);
  overflow: hidden;
  z-index: 1;
}
.st-phones {
  object-fit: cover;
  position: absolute;
  inset: 95% 0 0 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
  margin: 0 !important;
  width: 1000px;
  z-index: 1;
}
.bg-icon {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  max-width: 1440px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.app-info,
.olimp-description,
.olimp {
  align-self: stretch;
}
.app-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.olimp {
  margin: 0 auto;
  position: relative;
  line-height: 40px;
  font-family: inherit;
  z-index: 1;
  font-size: 60px;
  font-weight: 700;
  line-height: 72px;
  text-align: center;
}

.olimp-description {
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  width: 503px;
  margin: 0 auto;
  z-index: 1;
}
.download-buttons-container,
.frame-group {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.download-buttons-container {
  align-self: stretch;
  padding: 0 38px 0 39px;
  justify-content: center;
}
.frame-button {
  cursor: pointer;
  padding: var(--padding-base) var(--padding-21xl);
  background-color: var(--color-mintcream);
  border-radius: var(--br-13xl);
  box-sizing: border-box;
  z-index: 1;
  border: 0;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 149px;
  min-width: 84px;
  display: flex;
  box-shadow: 0 0 4px #00000080, inset 0 -2px #00000040;
}
.frame-group {
  gap: var(--gap-5xs);
}
.frame-icon2 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 20px;
}
.ios {
  position: relative;
  font-size: var(--font-size-sm);
  line-height: 20px;
  font-weight: 500;
  font-family: var(--font-inter);
  color: var(--color-black);
  text-align: center;
  display: inline-block;
  min-width: 24px;
}

.android {
  position: relative;
  font-size: var(--font-size-sm);
  line-height: 20px;
  font-weight: 500;
  font-family: var(--font-inter);
  color: var(--color-black);
  text-align: center;
  display: inline-block;
  min-width: 53px;
}

.frame-div,
.parent {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.parent {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-5xl);
}
.parent .olimp {
  width: 485px;
}
.frame-div {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  padding: 0;
  box-sizing: border-box;
  font-size: 48px;
  display: flex;
}
.p,
.p1 {
  margin: 0;
}
.div1,
.h1 {
  position: relative;
}
.h1 {
  margin: 0;
  align-self: stretch;
  line-height: 40px;
  font-weight: 700;
  font-family: inherit;
}
.fan-benefits {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.fan-benefits {
  align-self: stretch;
  align-items: flex-start;
  padding: 0 5px;
  gap: var(--gap-5xs);
}
.benefit-labels {
  border: 1px solid var(--color-gray-400);
  padding: var(--padding-5xs) var(--padding-mini);
  background-color: transparent;
  filter: drop-shadow(8px 16px 32px rgba(0, 0, 0, 0.15));
  backdrop-filter: blur(8px);
  border-radius: var(--br-13xl);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.benefit-labels-text {
  font-size: var(--font-size-sm);
  line-height: 18px;
  font-weight: 500;
  font-family: var(--font-inter);
  color: var(--color-gray-300);
  text-align: center;
  display: inline-block;
}

.div4 {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-sm);
  line-height: 20px;
  font-weight: 500;
  color: var(--black-20);
  z-index: 2;
  width: 311px;
  object-fit: cover;
}
.interface-container-parent {
  border-radius: var(--br-5xl);
  background-color: var(--color-gray-200);
  box-sizing: border-box;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  gap: 167.7px;
  max-width: 100%;
  height: 720px;
  padding: 1px 0 0;
  display: flex;
  overflow: hidden;
}
.interface-container {
  position: relative;
  flex-shrink: 0;
}
.interface-container {
  /* width: 375px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-13xl) 674px;
  box-sizing: border-box;
  gap: var(--gap-xl);
  max-width: 110%;
}
.ellipse-parent {
  width: 100%;
  height: 100%;
  margin: 0 !important;
}
.frame-child {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background: radial-gradient(
    50% 50%at 50% 50%,
    rgba(14, 145, 85, 0.5),
    rgba(14, 145, 85, 0)
  );
  width: 100%;
  height: 100%;
}
.image-244-icon {
  object-fit: contain;
  z-index: 2;
  text-align: center;
  width: 311px;
  height: 631px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: 150px;
  left: 50%;
  right: 0;
  transform: translateX(-50%);
}
.frame-parent2,
.nested-interface {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.nested-interface {
  align-self: stretch;
  justify-content: center;
  padding: 0 var(--padding-xl);
}
.frame-parent2 {
  /* width: 76px; */
  backdrop-filter: blur(8px);
  border-radius: var(--br-13xl);
  background-color: var(--color-gray-400);
  border: 1px solid var(--color-gray-500);
  box-sizing: border-box;
  justify-content: flex-start;
  padding: 0 2px;
  z-index: 2;
}

.frame-icon4 {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.frame-wrapper1 {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  height: 36px;
  width: 36px;
}
.frame-wrapper1.active {
  border-radius: var(--br-13xl);
  background-color: var(--color-gray-400);
}
.frame-icon5 {
  width: 16px;
  height: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.interface-description {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.convenience-heading {
  text-align: center;
  width: 100%;
}
.b {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
}
.interface-container {
  position: relative;
  flex-shrink: 0;
}
.feature-icons {
  min-height: 343px;
  border-radius: var(--br-5xl);
  background-color: var(--color-gray-200);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xl) 138px var(--padding-xl) 139px;
  box-sizing: border-box;
  position: relative;
  width: 100%;
}
.b1 {
  position: relative;
  display: inline-block;
  min-width: 66px;
  z-index: 1;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
}
.fast-payouts,
.wide-selection {
  position: absolute;
  margin: 0 !important;
}
.wide-selection {
  width: 100%;
  left: calc(50% - 187.5px);
}
.wide-selection-background {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  z-index: 5;
  top: 90px;
  left: -18%;
  background: radial-gradient(
    36.8% 53.58% at 63.72% 43.95%,
    rgba(15, 150, 86, 0.2) 0%,
    rgba(6, 93, 88, 0) 100%
  );
}
.bonus-description-icon {
  position: absolute;
  top: 46px;
  left: 57px;
  width: 261.4px;
  height: 104.5px;
  z-index: 1;
}
.two-black-dices-with-different-icon {
  position: absolute;
  top: 60px;
  left: 35px;
  width: 308.37px;
  height: 308.37px;
  object-fit: contain;
  z-index: 2;
}
.feature-icons1 {
  align-self: stretch;
  height: 343px;
  position: relative;
  border-radius: var(--br-5xl);
  background-color: var(--color-gray-200);
  overflow: hidden;
  flex-shrink: 0;
}
.feature-icons1-images {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
.feature-icons1-images-0 {
  display: flex;
  gap: 16px;
}
.feature-icons1-images-1 {
  display: flex;
  gap: 16px;
}
.fast-payouts {
  width: 634.8px;
  height: 632.6px;
  top: 226.53px;
  left: 599.2px;
  border-radius: 50%;
  background: radial-gradient(
    50% 50%at 50% 50%,
    rgba(15, 150, 86, 0.2),
    rgba(6, 93, 88, 0)
  );
  transform: rotate(145.4deg);
  transform-origin: 0 0;
  z-index: 1;
}
.rectangle-icon {
  position: absolute;
  top: 821px;
  left: 0;
  width: 748px;
  height: -317px;
  object-fit: contain;
}
.p2,
.p3 {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
}
.b2 {
  line-height: 26px;
  margin: 20px auto 27px;
}
.image-250 {
  box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.15);
  border-radius: var(--br-45xl);
  border: 1px solid var(--color-gray-500);
  box-sizing: border-box;
  width: 104px;
  height: 104px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xl) var(--padding-lgi);
  position: relative;
  border-radius: 64px;
}
.image-250-icon {
  position: relative;
  width: 64px;
  height: 64px;
}
.color-1 {
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: #593987;
  box-shadow: 4px 8px 16px 0px rgba(0, 0, 0, 0.15);
}
.color-2 {
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: #231f20;
  box-shadow: 4px 8px 16px 0px rgba(0, 0, 0, 0.15);
}
.color-3 {
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: #d20515;
  box-shadow: 4px 8px 16px 0px rgba(0, 0, 0, 0.15);
}
.color-4 {
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: #07073f;
  box-shadow: 4px 8px 16px 0px rgba(0, 0, 0, 0.15);
}
.feature-icons2 {
  position: relative;
  overflow: hidden;
}
.feature-icons2 {
  align-self: stretch;
  height: 343px;
  border-radius: var(--br-5xl);
  background-color: var(--color-gray-200);
  flex-shrink: 0;
  font-size: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.h11 {
  margin: 32px auto;
  font-size: 48px;
  line-height: 40px;
  font-weight: 700;
  font-family: inherit;
  background: linear-gradient(162.76deg, #1eb769, #0f7273);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 2;
}

.wrapper-gift-front {
  top: 64px;
  left: -14px;
  width: 406px;
  height: 406px;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gift-front-icon {
  z-index: 3;
  justify-content: center;
  align-items: center;
  width: 406px;
  height: 406px;
  display: flex;
  position: relative;
  top: -80px;
}

.feature-icons-child,
.feature-icons3 {
  height: 343px;
  position: relative;
  border-radius: var(--br-5xl);
  box-sizing: border-box;
  max-width: 100%;
}
.feature-icons3 {
  align-self: stretch;
  background-color: var(--color-gray-200);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 23px 0 0;
}
.feature-icons-child {
  width: 343px;
  border: 8px solid var(--color-gray-200);
  display: none;
  z-index: 0;
}
.b3,
.bets-icon-parent {
  margin: 0 auto;
  z-index: 1;
}
.b3 {
  line-height: 26px;
  display: inline-block;
}
.frame-parent3 {
  margin-left: -16px;
  position: relative;
  flex-shrink: 0;
  max-width: 110%;
  font-size: var(--font-size-sm);
  color: var(--black-20);
}
.group-parent {
  align-items: center;
  gap: 8px;
  display: flex;
  flex-direction: column;
}
.group-parent-wrapper {
  box-sizing: border-box;
  backdrop-filter: blur(4px);
  background: #262626;
  border: 1px solid;
  border-radius: 20px;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  height: 94px;
  padding: 20px 32px;
  display: flex;
  transition: all 0.5s ease;
}
.group-parent-wrapper:nth-child(1) {
  border-image-source: linear-gradient(
    97.31deg,
    rgba(255, 255, 255, 0.075) 0%,
    rgba(255, 255, 255, 0) 25.5%,
    rgba(255, 255, 255, 0) 73.5%,
    rgba(255, 255, 255, 0.025) 100%
  );
}
.group-parent-wrapper:nth-child(2) {
  opacity: 0.7 !important;
  border-image-source: linear-gradient(
    97.31deg,
    rgba(255, 255, 255, 0.075) 0%,
    rgba(255, 255, 255, 0) 25.5%,
    rgba(255, 255, 255, 0) 73.5%,
    rgba(255, 255, 255, 0.025) 100%
  );
}
.group-parent-wrapper:nth-child(3) {
  opacity: 0.4 !important;
  border-image-source: linear-gradient(
    97.31deg,
    rgba(255, 255, 255, 0.075) 0%,
    rgba(255, 255, 255, 0) 25.5%,
    rgba(255, 255, 255, 0) 73.5%,
    rgba(255, 255, 255, 0.025) 100%
  );
}
.group-parent-wrapper:nth-child(4) {
  opacity: 0.1 !important;
  border-image-source: linear-gradient(
    97.31deg,
    rgba(255, 255, 255, 0.075) 0%,
    rgba(255, 255, 255, 0) 25.5%,
    rgba(255, 255, 255, 0) 73.5%,
    rgba(255, 255, 255, 0.025) 100%
  );
}

.group-parent-img-icon {
  height: 32px;
  width: 32px;
}
.group-parent-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}
.group-parent-money {
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
  color: #ffffff;
}
.group-parent-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #ffffff80;
}
.group-parent-time {
  margin-left: auto;
}
.group-icon {
  width: 518px;
  height: 314px;
  display: flex;
}
.bonus-icon {
  position: absolute;
  border-radius: 50%;
  background: radial-gradient(
    50% 50%at 50% 50%,
    rgba(14, 145, 85, 0.2),
    rgba(14, 145, 85, 0)
  );
  width: 100%;
  height: 748px;
  transform-origin: center;
  z-index: 0;
}

.div5 {
  font-size: var(--font-size-sm);
  color: var(--black-20);
  margin: 8px auto 32px;
}
.frame-parent3 {
  margin-left: -16px;
  width: 375px;
  height: 579px;
  position: relative;
  flex-shrink: 0;
  max-width: 110%;
}
.feature-icons-child,
.feature-icons3 {
  height: 343px;
  position: relative;
  border-radius: var(--br-5xl);
  box-sizing: border-box;
  max-width: 100%;
}
.feature-icons3 {
  align-self: stretch;
  background-color: var(--color-gray-200);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 32px 0 0;
  height: 348px;
}
.feature-icons-child {
  width: 343px;
  border: 8px solid var(--color-gray-200);
  z-index: 0;
}

.images-lightning {
  top: 249.5px;
  left: 163.5px;
  width: 48px;
  height: 48px;
  z-index: 3;
}
.frame-parent4 {
  margin: 0 auto;
}

.frame-inner {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url('./public/wheel.svg');
  width: 498px;
  height: 498px;
  position: absolute;
  padding: 0;
  margin: 0;
  border-radius: 100%;
}
.h3 {
  margin: 16px auto 0;
  position: relative;
  font-size: inherit;
  line-height: 28px;
  font-weight: 700;
  font-family: inherit;
  z-index: 3;
}
.wrapper {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 auto;
  display: flex;
}
.div6 {
  width: 295px;
  position: relative;
  font-size: var(--font-size-sm);
  line-height: 20px;
  font-weight: 500;
  color: var(--black-20);
  display: inline-block;
  z-index: 3;
  padding: 0;
  margin: 8px 0;
}
.feature-icons4,
.frame-section-wrapper,
.frame-section {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  gap: var(--gap-5xs);
  max-width: 100%;
}
.feature-icons4 {
  border-radius: var(--br-5xl);
  background-color: var(--color-gray-200);
  border: 8px solid var(--color-gray-200);
  z-index: 1;
  font-size: 20px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 343px;
  padding: 0;
  margin: 0;
}
.frame-section {
  border-radius: 0 0 var(--br-5xl) var(--br-5xl);
  background-color: var(--black-95);
  text-align: center;
  font-size: var(--font-size-xl);
  color: var(--black-0);
  font-family: var(--font-inter);
  padding: 120px 80px;
}
.image-251-icon
/* .mask-group-icon */ {
  width: 100%;
  height: 100%;
  position: absolute;
}

.image-251-icon {
  object-fit: contain;
  border-radius: 18px;
}
.wrapper-image-251 {
  width: 96px;
  height: 96px;
  position: relative;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.div7,
.h13 {
  align-self: stretch;
  position: relative;
}
.h13 {
  margin: 0;
  font-size: inherit;
  line-height: 36px;
  font-weight: 700;
  font-family: inherit;
}
.div7 {
  font-size: var(--font-size-base);
  line-height: 24px;
  font-weight: 500;
  color: var(--black-10);
  width: 503px;
  margin: 0 auto;
}
.app-preview,
.download-call-to-action {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.app-preview {
  gap: var(--gap-5xl);
}
.button-placeholders-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 20px;
}
.ios1 {
  position: relative;
  font-size: var(--font-size-sm);
  line-height: 20px;
  font-weight: 500;
  font-family: var(--font-inter);
  color: var(--color-black);
  text-align: center;
  display: inline-block;
  min-width: 24px;
}
.button-placeholders-parent {
  cursor: pointer;
  border: 0;
  padding: var(--padding-base) var(--padding-21xl);
  background-color: var(--color-mintcream);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5), 0-2px 0 rgba(0, 0, 0, 0.25) inset;
  border-radius: var(--br-13xl);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
}
.frame-icon6 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 20px;
}
.android1 {
  position: relative;
  font-size: var(--font-size-sm);
  line-height: 20px;
  font-weight: 500;
  font-family: var(--font-inter);
  color: var(--color-black);
  text-align: center;
  display: inline-block;
  min-width: 53px;
}
.buttons,
.frame-parent5 {
  display: flex;
  flex-direction: row;
}
.frame-parent5 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-base) var(--padding-7xl);
  background-color: var(--color-mintcream);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5), 0-2px 0 rgba(0, 0, 0, 0.25) inset;
  border-radius: var(--br-13xl);
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
}
.buttons {
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-xl);
  gap: var(--gap-5xs);
}
.download-info,
.rd,
.rd-wrapper {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.download-info {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-21xl);
  z-index: 1;
}
.rd,
.rd-wrapper {
  flex-direction: row;
  align-items: flex-start;
  box-sizing: border-box;
}
.rd {
  flex: 1;
  padding: 212px var(--padding-base);
  position: relative;
  min-height: 800px;
}
.rd-wrapper {
  align-self: stretch;
  text-align: center;
  font-size: 28px;
  color: var(--black-0);
  font-family: var(--font-inter);
}
.div8,
.refund-policy {
  position: relative;
}
.refund-policy {
  display: inline-block;
  min-width: 91px;
}
.aml {
  position: relative;
  min-width: 100px;
}
.aml,
.div10,
.div9 {
  display: inline-block;
}
.div9 {
  position: relative;
  min-width: 67px;
}
.div10 {
  min-width: 59px;
}
.div10,
.div11 {
  position: relative;
}
.footer-links {
  border-bottom: 1px solid var(--black-90);
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  padding: 0 0 14px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.olimp1 {
  position: relative;
  display: inline-block;
  min-width: 95px;
}

.landing {
  width: 100%;
  position: relative;
  background-color: var(--color-black);
  overflow: hidden;
  flex-direction: column;
  padding: 0 0 var(--padding-13xl);
  line-height: normal;
  letter-spacing: normal;
}

.footer-links a {
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--black-60);
  font-family: var(--font-inter);
  white-space: nowrap;
}

.frame-section {
  display: grid;
  grid-template: 1fr 1fr 1fr 1fr / 1fr 1fr 1fr;
  gap: 24px;
  height: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

.frame-div {
  grid-row: 1 / 2;
  grid-column: 1 / 4;
  border-radius: 32px;
}

.interface-container-parent {
  grid-row: 2 / 4;
  grid-column: 1 / 2;
  border-radius: 32px;
}

.feature-icons {
  grid-row: 2 / 3;
  grid-column: 2 / 3;
  border-radius: 32px;
}

.feature-icons1 {
  grid-row: 2 / 3;
  grid-column: 3 / 4;
  border-radius: 32px;
}

.feature-icons2 {
  grid-row: 3 / 4;
  grid-column: 2 / 4;
  border-radius: 32px;
}

.feature-icons3 {
  grid-row: 4 / 5;
  grid-column: 1 / 3;
  border-radius: 32px;
}

.feature-icons4 {
  grid-row: 4 / 5;
  grid-column: 3 / 4;
  border-radius: 32px;
}

.frame-section-wrapper {
  border-radius: 0 0 var(--br-5xl) var(--br-5xl);
  background-color: var(--black-95);
  text-align: center;
  font-size: var(--font-size-xl);
  color: var(--black-0);
  font-family: var(--font-inter);
  margin: 0 auto;
  width: 100%;
  z-index: 1;
}
.buttons-mobile-sticky {
  display: none;
}

.linear-animation {
  position: absolute;
  height: 100%;
  width: 100%;
  filter: blur(46px);
  transform: scale(0.5, 0.5);
  border-radius: 100%;
  top: 30%;
  background-color: rgba(14, 145, 85, 0.15);
  transition: all 0.5s ease;
}

.feature-icons2:hover .linear-animation {
  backface-visibility: hidden;
  animation: animation-timing 3s linear;
  background-color: rgba(14, 145, 85, 0.6);
}

.linear-animation-element {
  height: 100%;
  border-radius: 100%;

  background-position: center center;
  border-color: transparent;
  border-style: solid;
  transform: translateZ(0px);
  transform-origin: center center;
}

@keyframes animation-timing {
  0% {
    opacity: 0.5;
    transform: scale(0.5, 0.5);
    animation-timing-function: step-start;
  }
  100% {
    opacity: 1;
    transform: scale(0.8, 0.8);
    animation-timing-function: step-end;
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.feature-icons4:hover .rotating {
  animation: rotating 5s linear infinite;
}

.mask-group-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('./public/mask-group.svg') no-repeat center center;
  background-size: cover;
}
.hidden-element {
  opacity: 0;
  transition: opacity 1s ease-in-out;
  position: relative;
  padding: 20px;
}

.hidden-element.visible {
  opacity: 1;
}

.popup-open-android:hover {
  cursor: pointer;
}
.frame-button.popup-open-android:hover {
  cursor: pointer;
  box-shadow: 0px -2px 0px 0px #00000040 inset;
  box-shadow: 0px 0px 4px 0px #00000080;
  background: #d7eedf;
  transition: all 0.5s ease;
}

.frame-wrapper .popup-open-android:hover {
  cursor: pointer;
  box-shadow: 0px -2px 0px 0px #00000040 inset;
  box-shadow: 0px 0px 4px 0px #00000080;
  background: #d7eedf;
  transition: all 0.5s ease;
}

.frame-button.popup-open-android:active {
  background-color: var(--black-0);
  box-shadow: none;
}

.frame-wrapper .popup-open-android:hover {
  background-color: var(--color-mintcream);
  box-shadow: none;
}

.scroll-container {
  width: 375px;
  overflow: hidden;
  position: relative;
  height: 104px;
}

.carousel-primary {
  display: flex;
  width: calc(12 * 120px); /* 12 elements * (120px + 10px margin) */
  position: absolute;
}

.carousel-primary-1 {
  animation: slideLeft 3500ms linear infinite paused;
  animation-delay: 100ms;
  transform: translateX(20px);
}

.carousel-primary-2 {
  animation: slideRight 4000ms linear infinite paused;
  animation-delay: 100ms;
  transform: translateX(-40px);
}

.feature-icons1:hover .carousel-primary-1,
.feature-icons1:hover .carousel-primary-2 {
  animation-play-state: running;
}

.item {
  width: 120px;
  height: 100px; /* Adjust as needed */
  background-color: #ddd;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes slideRight {
  0% {
    transform: translateX(-40px);
  }
  100% {
    transform: translateX(calc(-8 * 120px));
  }
}

@keyframes slideLeft {
  0% {
    transform: translateX(calc(-6 * 120px));
  }
  100% {
    transform: translateX(0);
  }
}

.st-images {
  position: absolute;
  width: 1440px;
  bottom: 0;
  height: 100%;
  margin: 0 auto;
  left: 50%;
  transform: translate(-50%, 0%);
}

.st-ererww-1 {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
  animation: sbs-ererww-1 6s infinite linear;
  backface-visibility: hidden;
}
.st-ererww-2 {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;
  animation: sbs-ererww-2 10s infinite linear;
  backface-visibility: hidden;
}
.st-soccer-ball {
  position: absolute;
  right: 0;
  top: 44%;
  animation: sbs-soccer-ball 8s infinite linear;
  backface-visibility: hidden;
}
.st-basketball {
  position: absolute;
  left: 0;
  top: 35%;
  animation: sbs-basketball 9s infinite linear;
  backface-visibility: hidden;
}

@keyframes sbs-basketball {
  0% {
    transform: translateY(0px);
    animation-timing-function: 0;
  }
  50% {
    transform: translateY(20px);
    animation-timing-function: 0;
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes sbs-soccer-ball {
  0% {
    transform: translateY(0px);
    animation-timing-function: 0;
  }
  50% {
    transform: translateY(10px);
    animation-timing-function: 0;
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes sbs-ererww-1 {
  0% {
    transform: translateY(0px);
    animation-timing-function: 0;
  }
  50% {
    transform: translateY(20px);
    animation-timing-function: 0;
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes sbs-ererww-2 {
  0% {
    transform: translateY(0px);
    animation-timing-function: 0;
  }
  50% {
    transform: translateY(30px);
    animation-timing-function: 0;
  }
  100% {
    transform: translateY(0px);
  }
}
